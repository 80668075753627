<script setup>
const { breakpoint } = useBreakpoints();
const props = defineProps({
    preheader: String,
    header: String,
    opened: Boolean
})

const isOpen = ref(props.opened);

</script>

<template>
  <div class="flex flex-col sm:flex-row border-b border-gray-400 sm:border-0 mb-2 sm:mb-0">
    <div class="grow order-3 sm:order-2">
      <div class="hidden sm:block text-claret">{{ preheader }}</div>
      <div class="flex justify-between items-center px-4 sm:px-0 mb-2 sm:mb-0" @click="isOpen = !isOpen">
        <div class="text-base sm:text-2xl leading-relaxed py-2">{{ header }}</div>
        <div class="sm:hidden">
          <svg v-if="isOpen" width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1H1" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1V7M7 7V13M7 7H13M7 7H1" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <StaticSeparator class="hidden sm:block !my-3" />
      <div class="pt-3 px-8 pb-4 sm:px-0 sm:pb-0" :class="{'hidden':!isOpen && breakpoint === 'sm'}">
        <slot />
      </div>
    </div>
  </div>
</template>