<template>
  <svg class="inline-block" width="132" height="42" viewBox="0 0 132 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.6 20.1V22.6H88V24.2H89.6V29.8C89.6 31.7 90.5 32.5 92.6 32.5C93 32.5 93.3 32.5 93.7 32.4V30.8C93.4 30.8 93.3 30.8 93 30.8C92 30.8 91.6 30.3 91.6 29.3V24.2H93.7V22.6H91.6V20.1H89.6Z" fill="black"/>
    <path d="M99.2 32.6C102.1 32.6 103.9 30.7 103.9 27.5C103.9 24.3 102.1 22.4 99.2 22.4C96.3 22.4 94.5 24.3 94.5 27.5C94.6 30.7 96.3 32.6 99.2 32.6ZM99.2 30.9C97.5 30.9 96.6 29.7 96.6 27.5C96.6 25.4 97.6 24.1 99.2 24.1C100.9 24.1 101.8 25.4 101.8 27.5C101.9 29.7 100.9 30.9 99.2 30.9Z" fill="black"/>
    <path d="M105.4 32.5H107.3V26.7C107.3 25.3 108.3 24.4 109.8 24.4C110.1 24.4 110.7 24.5 110.9 24.5V22.6C110.7 22.5 110.3 22.5 110 22.5C108.7 22.5 107.6 23.2 107.3 24.2H107.2V22.7H105.3L105.4 32.5Z" fill="black"/>
    <path d="M115.8 24.1C117.2 24.1 118.2 25.1 118.2 26.7H113.2C113.3 25.1 114.3 24.1 115.8 24.1ZM118.2 29.8C117.8 30.6 117 31 115.9 31C114.3 31 113.3 29.9 113.3 28.2V28.1H120.3V27.4C120.3 24.3 118.6 22.4 115.9 22.4C113.1 22.4 111.3 24.4 111.3 27.6C111.3 30.8 113 32.7 115.9 32.7C118.2 32.7 119.7 31.6 120.2 29.8H118.2Z" fill="black"/>
    <path d="M76.7998 28.8C76.9998 31.2 78.9998 32.8 81.9998 32.8C85.1998 32.8 87.1998 31.2 87.1998 28.6C87.1998 26.6 86.0998 25.4 83.2998 24.8L81.7998 24.4C79.9998 24 79.2998 23.4 79.2998 22.5C79.2998 21.3 80.3998 20.5 82.0998 20.5C83.6998 20.5 84.7998 21.3 84.9998 22.5H86.9998C86.8998 20.2 84.8998 18.6 82.0998 18.6C79.0998 18.6 77.1998 20.2 77.1998 22.6C77.1998 24.6 78.2998 25.8 80.7998 26.4L82.4998 26.8C84.2998 27.2 85.0998 27.8 85.0998 28.9C85.0998 30.1 83.8998 31 82.1998 31C80.3998 31 79.0998 30.2 78.8998 28.9L76.7998 28.8Z" fill="black"/>
    <path d="M56.6 22.4C55.3 22.4 54.1 23.1 53.5 24.2H53.4V22.6H51.5V35.8H53.5V31H53.7C54.2 32 55.3 32.7 56.7 32.7C59.2 32.7 60.8 30.7 60.8 27.6C60.7 24.4 59.1 22.4 56.6 22.4ZM56.1 30.9C54.5 30.9 53.4 29.6 53.4 27.6C53.4 25.5 54.4 24.2 56.1 24.2C57.8 24.2 58.7 25.5 58.7 27.5C58.7 29.6 57.7 30.9 56.1 30.9Z" fill="black"/>
    <path d="M67.6 22.4C66.3 22.4 65.1 23.1 64.5 24.2H64.4V22.6H62.5V35.8H64.5V31H64.7C65.2 32 66.3 32.7 67.7 32.7C70.2 32.7 71.8 30.7 71.8 27.6C71.7 24.4 70.1 22.4 67.6 22.4ZM67 30.9C65.4 30.9 64.3 29.6 64.3 27.6C64.3 25.5 65.3 24.2 67 24.2C68.7 24.2 69.6 25.5 69.6 27.5C69.6 29.6 68.7 30.9 67 30.9Z" fill="black"/>
    <path d="M47.9992 32.5H50.1992L45.2992 18.9H43.0992L38.1992 32.5H40.3992L41.5992 28.9H46.7992L47.9992 32.5ZM44.0992 21.4H44.2992L46.2992 27.1H42.0992L44.0992 21.4Z" fill="black"/>
    <path d="M39.5 8.70001V15.2H41.9C43.9 15.2 45 14 45 11.9C45 9.80001 43.9 8.60001 41.9 8.60001H39.5V8.70001ZM40.5 9.60001H41.7C43.1 9.60001 43.9 10.5 43.9 11.9C43.9 13.4 43.1 14.3 41.7 14.3H40.5V9.60001Z" fill="black"/>
    <path d="M48.3996 15.3C49.7996 15.3 50.6996 14.3 50.6996 12.7C50.6996 11.1 49.7996 10.1 48.3996 10.1C46.9996 10.1 46.0996 11.1 46.0996 12.7C46.0996 14.3 46.9996 15.3 48.3996 15.3ZM48.3996 14.4C47.5996 14.4 47.0996 13.8 47.0996 12.7C47.0996 11.6 47.5996 11 48.3996 11C49.1996 11 49.6996 11.6 49.6996 12.7C49.6996 13.8 49.1996 14.4 48.3996 14.4Z" fill="black"/>
    <path d="M58.2994 10.3H57.2994L56.3994 14H56.2994L55.2994 10.2H54.3994L53.3994 14H53.2994L52.3994 10.2H51.3994L52.7994 15.1H53.7994L54.7994 11.5H54.8994L55.8994 15.1H56.8994L58.2994 10.3Z" fill="black"/>
    <path d="M59.3994 15.2H60.3994V12.3C60.3994 11.5 60.8994 11 61.5994 11C62.2994 11 62.6994 11.4 62.6994 12.2V15.2H63.6994V12C63.6994 10.8 63.0994 10.2 61.9994 10.2C61.2994 10.2 60.7994 10.5 60.4994 11.1H60.3994V10.3H59.4994L59.3994 15.2Z" fill="black"/>
    <path d="M65.0996 15.2H66.0996V8.3H65.0996V15.2Z" fill="black"/>
    <path d="M69.6994 15.3C71.0994 15.3 71.9994 14.3 71.9994 12.7C71.9994 11.1 71.0994 10.1 69.6994 10.1C68.2994 10.1 67.3994 11.1 67.3994 12.7C67.3994 14.3 68.2994 15.3 69.6994 15.3ZM69.6994 14.4C68.8994 14.4 68.3994 13.8 68.3994 12.7C68.3994 11.6 68.8994 11 69.6994 11C70.4994 11 70.9994 11.6 70.9994 12.7C71.0994 13.8 70.5994 14.4 69.6994 14.4Z" fill="black"/>
    <path d="M74.9996 14.5C74.4996 14.5 74.0996 14.2 74.0996 13.8C74.0996 13.4 74.3996 13.1 75.0996 13.1L76.2996 13V13.4C76.2996 14 75.6996 14.5 74.9996 14.5ZM74.6996 15.3C75.2996 15.3 75.8996 15 76.1996 14.5H76.2996V15.2H77.1996V11.8C77.1996 10.8 76.4996 10.1 75.2996 10.1C74.1996 10.1 73.3996 10.6 73.2996 11.5H74.1996C74.2996 11.2 74.6996 11 75.1996 11C75.8996 11 76.1996 11.3 76.1996 11.8V12.2L74.8996 12.3C73.6996 12.4 73.0996 12.9 73.0996 13.8C73.0996 14.7 73.7996 15.3 74.6996 15.3Z" fill="black"/>
    <path d="M80.5 15.3C81.2 15.3 81.8 15 82.1 14.4H82.2V15.2H83.1V8.3H82.1V11H82C81.7 10.5 81.2 10.1 80.5 10.1C79.3 10.1 78.5 11.1 78.5 12.6C78.5 14.3 79.3 15.3 80.5 15.3ZM80.8 11.1C81.6 11.1 82.1 11.7 82.1 12.8C82.1 13.8 81.6 14.5 80.8 14.5C80 14.5 79.5 13.9 79.5 12.8C79.5 11.7 80 11.1 80.8 11.1Z" fill="black"/>
    <path d="M89.3996 15.3C90.7996 15.3 91.6996 14.3 91.6996 12.7C91.6996 11.1 90.7996 10.1 89.3996 10.1C87.9996 10.1 87.0996 11.1 87.0996 12.7C87.0996 14.3 87.9996 15.3 89.3996 15.3ZM89.3996 14.4C88.5996 14.4 88.0996 13.8 88.0996 12.7C88.0996 11.6 88.5996 11 89.3996 11C90.1996 11 90.6996 11.6 90.6996 12.7C90.6996 13.8 90.2996 14.4 89.3996 14.4Z" fill="black"/>
    <path d="M93 15.2H94V12.3C94 11.5 94.5 11 95.2 11C95.9 11 96.3 11.4 96.3 12.2V15.2H97.3V12C97.3 10.8 96.7 10.2 95.6 10.2C94.9 10.2 94.4 10.5 94.1 11.1H94V10.3H93.1L93 15.2Z" fill="black"/>
    <path d="M101.699 9V10.3H100.899V11.1H101.699V13.9C101.699 14.9 102.099 15.2 103.199 15.2C103.399 15.2 103.599 15.2 103.699 15.1V14.3C103.599 14.3 103.499 14.3 103.299 14.3C102.799 14.3 102.599 14.1 102.599 13.6V11.1H103.699V10.3H102.599V9H101.699Z" fill="black"/>
    <path d="M105.1 15.2H106.1V12.3C106.1 11.6 106.5 11 107.3 11C108 11 108.4 11.4 108.4 12.2V15.1H109.4V12C109.4 10.8 108.8 10.2 107.7 10.2C107 10.2 106.4 10.5 106.2 11.1H106.1V8.39999H105.1V15.2Z" fill="black"/>
    <path d="M112.8 11C113.5 11 114 11.5 114 12.3H111.5C111.6 11.5 112.1 11 112.8 11ZM114 13.9C113.8 14.3 113.4 14.5 112.8 14.5C112 14.5 111.5 14 111.5 13.1V13H115V12.7C115 11.1 114.2 10.2 112.8 10.2C111.4 10.2 110.5 11.2 110.5 12.8C110.5 14.4 111.4 15.3 112.8 15.3C113.9 15.3 114.7 14.8 114.9 13.9H114Z" fill="black"/>
    <path d="M27.5996 21.3C27.5996 19.5 28.5996 17.7 30.1996 16.8C29.1996 15.4 27.5996 14.5 25.7996 14.4C23.9996 14.2 22.1996 15.5 21.2996 15.5C20.2996 15.5 18.8996 14.4 17.3996 14.5C15.3996 14.6 13.4996 15.7 12.4996 17.5C10.3996 21.2 11.9996 26.5 13.9996 29.5C14.9996 30.9 16.1996 32.6 17.6996 32.5C19.1996 32.4 19.7996 31.5 21.5996 31.5C23.3996 31.5 23.8996 32.5 25.4996 32.4C27.0996 32.4 28.1996 30.9 29.0996 29.5C29.7996 28.5 30.3996 27.3 30.7996 26.1C28.8996 25.3 27.5996 23.4 27.5996 21.3Z" fill="black"/>
    <path d="M24.6 12.5C25.5 11.4 25.9 10.1 25.8 8.7C24.4 8.8 23.2 9.5 22.3 10.5C21.4 11.5 21 12.8 21.1 14.2C22.4 14.2 23.8 13.5 24.6 12.5Z" fill="black"/>
    <path d="M8.19961 1H124C127.9 1 131.1 4 131.1 7.7V34.4C131.1 38.1 127.9 41.1 124 41.1H8.19961C4.29961 41.1 1.09961 38.1 1.09961 34.4V7.7C1.09961 4 4.29961 1 8.19961 1Z" stroke="black"/>
  </svg>

</template>

<script>
export default {
  name: "IconAppStore"
}
</script>

<style scoped>

</style>