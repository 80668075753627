<script setup>
const props = defineProps({
  title: String,
  phone: String,
  email: String
})
</script>

<template>
<div>
  <div class="text-lg mb-2">{{ title }}</div>
  <div class="font-light mb-2">
  <slot />
  </div>
  <p class="block pb-2" v-if="props.phone">
    <IconPhone class="inline-block mr-2" />
    <a :href="'tel:' + phone.replace(/\s/g,'')">{{ phone }}</a>
  </p>
  <p class="block pb-2" v-if="props.email">
    <IconMail class="inline-block mr-2" />
    <a :href="'mailto:'+email">{{ email }}</a>
  </p>
</div>
</template>
