<script setup>
useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]
});
const backend = useBackend();
</script>

<template>
  <Container>
    <h1 class="text-3xl lg:text-5xl text-center lg:text-left text-vehis-red">KONTAKT</h1>
    <StaticSeparator />

    <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-10">
      <div class="col-span-2 grid grid-cols-1 lg:grid-cols-1 lg:gap-6">
        <StaticContactBox preheader=" " header="Chcę zapytać o ofertę" class="pb-0" opened>
          <div class="font-light">
            Infolinia handlowa czynna:
            <div>od poniedziałku do piątku w godzinach 9.00 - 21.00</div>
            <div>w sobotę w godzinach 10:00 - 16:00</div>
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48221049205">+48 22 104 92 05</a>
            </p>
            <p class="block pb-2">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:twojdoradca@vehis.pl">twojdoradca@vehis.pl</a>
            </p>
          </div>
        </StaticContactBox>
        <StaticContactBox preheader="" header="Chcę porozmawiać o swojej umowie" ref="refForCustomersElement" class="lg:pb-12" opened>
          <div class="font-light">
            Biuro Obsługi Klienta czynne od poniedziałku do piątku 
            <div>w godzinach 9.00 - 17.00</div>
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48323088444">+48 32 308 84 44</a>
            </p>
            <p class="block pb-6">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:bok@vehis.pl">bok@vehis.pl</a>
            </p>
            <ButtonRounded class="hidden md:flex bg-sad-pink border-sad-pink max-w-48 min-w-48"
                     href="https://klient.vehis.pl"
                     target="_blank"
                     with-icon><IconUser class="mr-2"/> Portal Klienta</ButtonRounded>
          </div>
        </StaticContactBox>
      </div>

      <div class="pt-12 lg:pt-0">
        <FormContact />
      </div>
    </div>

    <StaticContactBox preheader=" " header="Lista oddziałów" class="lg:pb-12 mt-12 lg:mt-0" opened>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        <div class="md:col-span-2 lg:row-span-3 mb-12 flex justify-center">
          <LazyVectorBranchesMap class="hidden lg:inline-block w-full max-w-[640px]" />
          <LazyVectorBranchesMapSmall class="lg:hidden w-full max-w-[325px]" />
        </div>

        <StaticAddressBox title="Katowice (siedziba główna)"
                          phone="+48 22 103 39 74"
                          email="katowice@vehis.pl">
          al. Murckowska 14c, 40-265 Katowice<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Bydgoszcz"
                          phone="+48 52 880 88 78"
                          email="bydgoszcz@vehis.pl">
          ul. Grudziądzka 3G, 85-130 Bydgoszcz<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Elbląg"
                          phone="+48 55 881 15 88"
                          email="elblag@vehis.pl">
          ul. 1 Maja 45, 82-300 Elbląg<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Gdańsk"
                          phone="+48 58 881 44 95"
                          email="gdansk@vehis.pl">
          ul. Piastowska 11 lok. 214, 80-332 Gdańsk<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Poznań"
                          phone="+48 61 678 19 92"
                          email="poznan@vehis.pl">
          ul. Kopanina 30A, 60-105 Poznań<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>
        <StaticAddressBox title="Warszawa Bemowo"
                          phone="+48 22 103 25 59"
                          email="warszawa.bemowo@vehis.pl">
          ul. Połczyńska 31A, 01-377 Warszawa<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>
        <StaticAddressBox title="Łódź"
                          phone="+48 42 203 62 49"
                          email="lodz@vehis.pl">
                          ul. Dowborczyków 30/34 I piętro, 90-019 Łódź<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>
        <StaticAddressBox title="Kalisz"
                          phone="+48 62 888 11 25"
                          email="kalisz@vehis.pl">
          ul. Polna 32/6C, 62-800 Kalisz<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Rzeszów"
                          phone="+48 17 881 15 87"
                          email="rzeszow@vehis.pl">
          ul. Paderewskiego 124A lok. 202, 35-328 Rzeszów<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Warszawa II - Praga"
                          phone="+48 22 103 25 60"
                          email="warszawa.praga@vehis.pl">
          ul. Owsiana 12, 03-828 Warszawa<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>


        <StaticAddressBox title="Kraków"
                          phone="+48 12 881 73 86"
                          email="krakow@vehis.pl">
          ul. Bobrzyńskiego 12, p. 3 lok. 11, 30-348 Kraków<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Szczecin"
                          phone="+48 91 828 83 87"
                          email="szczecin@vehis.pl">
          ul. Storrady-Świętosławy 1C/4, 71-602 Szczecin<br />
          Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

        <StaticAddressBox title="Wrocław"
                          phone="+48 71 716 66 04"
                          email="wroclaw@vehis.pl">
          ul. Piłsudskiego 69, 50-019 Wrocław<br />
          Godziny otwarcia: Pon. - Pt. 8.00 - 16.00
        </StaticAddressBox>

        <StaticAddressBox title="Zielona Góra"
                          phone="+48 68 888 10 07"
                          email="zielona.gora@vehis.pl">
                          ul. Bohaterów Westerplatte 24, 65-034 Zielona Góra
                          <br />
            Godziny otwarcia: Pon. - Pt. 9.00 - 17.00
        </StaticAddressBox>

         <StaticAddressBox title=""/>

        <StaticContactBox preheader=" " header="Chcę umówić się do serwisu">
          <div class="font-light">
            Biuro Obsługi Klienta czynne od poniedziałku do piątku w godzinach 9.00 - 17.00 
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48323088444">+48 32 30 88 444</a>
            </p>
            <p class="block pb-2">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:serwis@vehis.pl">serwis@vehis.pl</a>
            </p>
          </div>
        </StaticContactBox>

        <StaticContactBox preheader=" " header="Chcę nowe ubezpieczenie">
          <div class="font-light">
            Ubezpieczenia VEHIS czynne od poniedziałku do piątku w godzinach 9.00 - 17.00 
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48729086923">+48 729 086 923</a>
            </p>
            <p class="block pb-2">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:ubezpieczenia@vehis.pl">ubezpieczenia@vehis.pl</a>
            </p>
          </div>
        </StaticContactBox>

        <StaticContactBox preheader=" " header="Chcę zgłosić szkodę">
          <div class="font-light">
            Zgłaszanie szkód możliwe jest przez całą dobę
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48323088444">+48 32 30 88 444</a>
            </p>
            <p class="block pb-2">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:obslugaszkody@vehis.pl">obslugaszkody@vehis.pl</a>
            </p>
          </div>
        </StaticContactBox>

        <StaticContactBox preheader=" " header="Chcę wznowić ubezpieczenie">
          <div class="font-light">
            Ubezpieczenia VEHIS czynne od poniedziałku do piątku w godzinach 9.00 - 17.00 
          </div>
          <div class="pt-2">
            <p class="block pb-2">
              <IconPhone class="inline-block mr-2" />
              <a href="tel:+48729086929">+48 729 086 929</a>
            </p>
            <p class="block pb-2">
              <IconMail class="inline-block mr-2" />
              <a href="mailto:wznowienia@vehis.pl">wznowienia@vehis.pl</a>
            </p>
          </div>
        </StaticContactBox>

        <StaticContactBox preheader=" " header="Aplikacja serwisowa">
          <div class="font-light">
            Pobierz aplikację serwisową VEHIS, dzięki której wygodnie umówisz się do serwisu lub zgłosisz likwidację szkody
          </div>
          <div class="py-6 flex gap-6">
            <a href="https://play.google.com/store/apps/details?id=com.mobiex.vehis" target="_blank">
              <VectorGooglePlay />
            </a>
            <a href="https://apps.apple.com/us/app/vehis/id1540990575" target="_blank">
              <VectorAppStore />
            </a>
          </div>
          <div class="font-light pt-2">
            Kod dostępu do aplikacji serwisowej VEHIS wyślemy w wiadomości SMS. Aby zarejestrować się w aplikacji, należy podać adres e-mail oraz otrzymany kod dostępu.
          </div>
          <div class="flex justify-center py-8">
            <a :href="backend.getDownloadLinkForFile('services')" class="text-azure" target="_blank">Lista partnerów serwisowych</a>
          </div>
        </StaticContactBox>

        <StaticContactBox preheader=" " header="Dane rejestrowe" class="md:col-span-2">
          <div class="font-light">
            <b>VEHIS sp. z o.o.</b>
            <div>z siedzibą w Katowicach (40-265), al. Murckowska 14c,</div>

            <div class="pt-4">wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Katowice-Wschód w Katowicach VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000778463,</div>
            <div>NIP: 1132995753,</div>
            <div>REGON: 382931136,</div>
            <div>Kapitał zakładowy 660 000 PLN</div>
          </div>
        </StaticContactBox>
      </div>
    </StaticContactBox>


  </Container>
</template>
